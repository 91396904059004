<template>
  <div class="fast-body-modo-aluno">
    <!--alerta vencimentos-->
    <div v-if="fastVencimentos.showAlert && fastVencimentos.vencidos" 
    class="alert alert-danger alert-dismissible fade show text-center font-weight-bold pointer mb-0" 
    role="alert1"
    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-boletos')">
      Você tem {{ this.fastVencimentos.vencidos }} em atraso
      <button type="button" class="close" data-dismiss="alert1" aria-label="Close" @click.prevent="fastVencimentos.showAlert = false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div v-if="fastVencimentos.showAlert && fastVencimentos.vencendo" 
    class="alert alert-primary alert-dismissible fade show text-center font-weight-bold pointer mb-0" 
    role="alert2"
    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-boletos')">
      Seu boleto vence {{ this.fastVencimentos.vencendo }}
      <button type="button" class="close" data-dismiss="alert2" aria-label="Close" @click.prevent="fastVencimentos.showAlert = false">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!--/alerta vencimentos-->
    <!--toast layout-->
    <b-toast
      v-if="this.$store.state.fastQntdLayouts > 1 && fastToastNewLayout == 'true'"
      visible
      title="Atualização!"
      no-auto-hide
      @change="alteraToastLayout()"
      toaster="b-toaster-bottom-right"
    >
      Você está visualizando a <b>Nova versão do Modo Aluno</b>, para voltar a versão anterior, clique abaixo: <br/>
      <button @click.prevent="alteraLayoutAluno()" class="btn btn-sm btn-primary-novo rounded mt-3">Alterar tema</button>
    </b-toast>
    <!--/toast layout-->
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container fast-plataforma-iuea-hide">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                href="#"
              >Modo aluno</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>      

      <div class="fd-mode-ead-conten-novo mt-0">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_ead"
              class="col-sm-12 col-md-12 col-lg-12 fd-mode-ead-content-body"
              style="margin-left: 0"
            >
              <div class="fd-mode-ead-conten-novo mt-0">
                <div class="container">
                  <div class="row justify-content-center">
                    <!-- fd-app-welcome-novo and fd-mode-opcoes -->
                    <div class="col-sm-12 col-md-12 col-lg-5 p-0 p-lg-3 p-md-3">
                      <div class="row">
                        <div class="col-12 p-lg-0 p-md-0">
                          <!-- fd-app-welcome-novo -->
                          <section class="fd-app-welcome-novo text-left">
                            <h2
                              v-if="$store.state.fastPersonalizacao.titulo_boas_vindas"
                              class="aluno_font_color"
                            >
                              {{ $store.state.fastPersonalizacao.titulo_boas_vindas.replace('[nome_aluno]', $store.state.fastUsuario.First_name) }}
                            </h2>
                            <h2
                              v-else
                              class="aluno_font_color"
                            >
                              Bem-vindo,
                              <span>{{ $store.state.fastUsuario.First_name }}</span>
                            </h2>
                            <p
                              class="aluno_font_color"
                            >
                              {{ $store.state.fastPersonalizacao.detalhes_boas_vindas ? $store.state.fastPersonalizacao.detalhes_boas_vindas.replace('[nome_aluno]', $store.state.fastUsuario.First_name) : 'Qual será o conhecimento adquirido hoje?' }}
                            </p>
                            <div class="fd-welcome-form-effect text-left">
                              <img
                              :src="require('@/assets/images/separador.png')"
                              >
                            </div>
                            <div>
                              <div class="fd-app-ranking">
                                <div class="row col-12">
                                  <div class="fd-app-ranking-content-novo">
                                    <transition name="slide-fade">
                                      <div
                                        class="d-flex justify-content-start mt-4"
                                      >
                                        <div class="my-auto" :style="{ display: 'flex', alignItems: 'center' }">
                                          <div
                                            class="fd-app-ranking-user-photo-novo"
                                            :style="{
                                              backgroundImage: 'url(' + ajustaLinkImageUser(fastUsuarioRanking.imagem_nivel) + ')'
                                            }"
                                          />
                                        </div>
                                        <div class="pl-3">
                                          <h4 class="aluno_font_color">
                                              {{ fastUsuarioRanking.nome_nivel }}
                                          </h4>
                                            
                                          <div
                                            v-if="fastPlataformaMedalhas.length"
                                            class="d-flex"
                                          >
                                            <div
                                              v-for="medalha in fastPlataformaMedalhas"
                                              :key="medalha.nivel_medalha"
                                            >
                                              <img
                                                v-lazy="ajustaLinkApiAntiga(medalha.imagem_medalha)"
                                                style="height: 40px; width: auto;"
                                              >
                                              <span
                                                v-if="medalha.quantidade"
                                              >{{ medalha.quantidade }}</span>
                                              <span v-else>0</span>
                                            </div>
                                          </div>
                                          <div class="d-flex flex-wrap" >
                                            <div
                                              class="d-flex justify-content-between align-items-left my-1"
                                            >
                                              <div>
                                                <span
                                                class="font-weight-bold aluno_font_color text-nowrap"
                                                >{{ fastUsuarioRanking.pontos_usuario }} pontos</span>
                                              </div>
                                            <div class="ml-4">
                                              <span
                                                class="btn-novo btn-sm btn-primary text-nowrap"
                                                style="cursor: pointer"
                                                @click="showModal('modalRegrasRanking')"
                                              >Ver Regras</span>
                                            </div>
                                          </div>
                                        </div>
                                        </div>
                                      </div>
                                    </transition>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <!-- /fd-app-welcome-novo -->
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <!-- fd-mode-opcoes -->
                          <section class="fd-mode-opcoes fd-mode-ead">
                           
                            <!-- Minhas turmas -->
                            <a  
                              v-if="$store.state.fastPlataformaModulos.modulo_secretaria && $store.state.fastPersonalizacao.aluno_visualiza_turmas"
                              class="row btn-menu btn-menu-blue p-2 mb-3"      
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turmas')"
                            >
                              <img                                    
                                  class="ml-3"
                                  v-if="$store.state.fastPersonalizacao.menu_icone_turmas"
                                  :src="ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_turmas)"
                              >
                              <img
                                v-else
                                class="ml-3"                                    
                                :src="require('@/assets/images/app/minhas-turmas.png')"
                              >
                              <h2 class="col-8 font-weight-normal" style="letter-spacing: -1px;">MINHAS TURMAS</h2>
                            </a>
                            <!-- /Minhas turmas -->

                            <!-- Meus Cursos -->
                            <a  
                              v-if="$store.state.fastPlataformaModulos.modulo_secretaria && $store.state.fastPersonalizacao.aluno_visualiza_meus_cursos"
                              class="row btn-menu btn-menu-blue p-2 mb-3"      
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos')"
                            >
                              <img                                    
                                  class="ml-3"
                                  v-if="$store.state.fastPersonalizacao.menu_icone_meus_cursos"
                                  :src="ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_meus_cursos)"
                              >
                              <img
                                v-else
                                class="ml-3"                                    
                                :src="require('@/assets/images/app/meus-cursos.png')"
                              >
                              <h2 v-if="$store.state.fastPersonalizacao.meus_cursos_nome" class="col-8 font-weight-normal text-uppercase" style="letter-spacing: -1px;">{{ $store.state.fastPersonalizacao.meus_cursos_nome }}</h2>
                              <h2 v-else class="col-8 font-weight-normal text-uppercase" style="letter-spacing: -1px;">MEUS CURSOS</h2>
                            </a>
                            <!-- /Meus Cursos -->

                            <!-- Boletos -->
                            <a  
                              class="row btn-menu btn-menu-blue p-2 mb-3"      
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-boletos'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-boletos')"
                            >
                              <img
                                class="ml-3"                    
                                :src="require('@/assets/images/app/boletos.png')"
                              >
                              <h2 class="col-8 font-weight-normal">BOLETOS</h2>
                            </a>
                            <!-- /Boletos -->

                            <!-- Secretaria -->
                            <a  
                              class="row btn-menu btn-menu-blue p-2 mb-3"      
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/secretaria')"
                            >
                              <img
                                :src="require('@/assets/images/app/secretaria.png')"
                                class="ml-3"                                  
                              >
                              <h2 class="col-8 font-weight-normal">SECRETARIA</h2>
                            </a>
                            <!-- /Secretaria -->

                            <!-- Meus Cadernos -->
                            <a  
                              class="row btn-menu btn-menu-blue p-2 mb-3"      
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cadernos'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cadernos')"
                            >
                              <img
                                :src="require('@/assets/images/app/meus-cadernos.png')"
                                class="ml-3"                                  
                              >
                              <h2 class="col-8 font-weight-normal">MEUS CADERNOS</h2>
                            </a>
                            <!-- /Meus Cadernos -->

                            <!-- Atendimento -->
                            <a  
                              v-if="$store.state.fastPersonalizacao.aluno_visualiza_atendimento"  
                              class="row btn-menu btn-menu-blue p-2 mb-3"      
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/atendimento'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/atendimento')"
                            >
                              <img
                                v-if="$store.state.fastPersonalizacao.menu_icone_atendimento"
                                class="ml-3"                                  
                                :src="ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_atendimento)"
                              >
                              <img
                                v-else
                                class="ml-3"                                  
                                :src="require('@/assets/images/app/atendimento.png')"
                              >
                              <h2 class="col-8 font-weight-normal">ATENDIMENTO</h2>
                            </a>
                            <!-- /Atendimento -->

                            <!-- Tutoriais -->
                            <a  
                              v-if="$store.state.fastPersonalizacao.aluno_visualiza_tutoriais"
                              class="row btn-menu btn-menu-orange p-2 mb-3"      
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/tutoriais'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/tutoriais')"
                            >
                              <img
                                v-if="$store.state.fastPersonalizacao.menu_icone_tutoriais"
                                class="ml-3"                                  
                                :src="ajustaLinkApiAntiga($store.state.fastPersonalizacao.menu_icone_tutoriais)"
                              >
                              <img
                                v-else
                                class="ml-3"                                  
                                :src="require('@/assets/images/app/tutorial.png')"
                              >
                              <h2 class="col-8 font-weight-normal">TUTORIAIS</h2>
                            </a>
                            <!-- /Tutoriais -->
                          </section>
                          <!-- /fd-mode-opcoes -->
                        </div>
                      </div>
                    </div>
                    <!-- /fd-app-welcome-novo and fd-mode-opcoes -->
                    <div class="col-sm-12 col-md-12 col-lg-2"></div>

                    <div class="col-sm-12 col-md-12 col-lg-5">
                      <a class="row justify-content-between btn-menu btn-menu-patio p-2 mt-4 mb-5"
                        :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/patio-virtual'"
                        @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/patio-virtual')"
                      >
                        <img
                          :src="require('@/assets/images/app/patio-virtual.png')"
                          class="ml-3"
                        >
                        <h3 class="font-weight-normal">PÁTIO VIRTUAL</h3>
                        <img
                          :src="require('@/assets/images/app/status.png')"
                          class="mr-3"
                        >
                      </a>
                      <div class="row mb-5">
                        <mural-avisos />
                      </div>
                      <div class="row">
                        <div v-if="!fastCalendarShow" class="col-12 text-center py-5">
                          <span>Carregando </span>
                          <b-icon-gear-fill class="text-orange" animation="spin-pulse" font-scale="1"/>
                        </div>
                        <FullCalendar v-else class='fd-app-calendar' :options='fastCalendarOptions'>
                          <template v-slot:eventContent='arg'>
                            <div @click.prevent="$router.push(arg.event.groupId)" id="tooltip-button-interactive" role="button" style="display: block; line-height:10px;" class="pl-2">
                              <b-icon-clipboard-check class="fd-app-calendar-icon" font-scale="1.1"/><br/>
                              <i>Avaliação</i>
                            </div>
                            <b-tooltip target="tooltip-button-interactive">
                              {{ arg.event.title }} 
                            </b-tooltip>
                          </template>
                        </FullCalendar>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3 mb-4">
            <a
              class="btn-novo btn-red-hollow-novo fast-iuea-ml-20"
              :href="'/plataforma/' + $route.params.id_plataforma"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
            >
              <b-icon-arrow-return-left /> Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modals -->
    <modal
      name="modalRegrasRanking"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Regras</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn-novo btn-secondary"
            href="#"
            @click.prevent="hideModal('modalRegrasRanking')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 pl-4 pr-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-1">
              <h6>Parâmetros do ranking</h6>
            </div>
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>
                      <small class="font-weight-bold">Atividade</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Pontos</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar atividade geral
                    </td>

                    <td
                      class="text-center align-middle"
                    >
                      {{ fastRankingParametros.ponto_atividade_geral }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Questão correta no QUIZ
                    </td>

                    <td
                      class="text-center align-middle"
                    >
                      {{ fastRankingParametros.ponto_acerto_questao_quiz }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold text-danger">
                      Questão errada no QUIZ
                    </td>

                    <td
                      class="text-center align-middle"
                    >
                      {{ fastRankingParametros.ponto_erro_questao_quiz }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar curso
                    </td>

                    <td
                      class="text-center align-middle"
                    >
                      {{ fastRankingParametros.ponto_completar_curso }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar curso acima da média
                    </td>

                    <td
                      class="text-center align-middle"
                    >
                      {{ fastRankingParametros.ponto_completar_curso_acima_media }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar matriz
                    </td>

                    <td
                      class="text-center align-middle"
                    >
                      {{ fastRankingParametros.ponto_completar_matriz }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar avaliação
                    </td>

                    <td
                      class="text-center align-middle"
                    >
                      {{ fastRankingParametros.ponto_completar_prova }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Completar avaliação acima da média
                    </td>

                    <td
                      class="text-center align-middle"
                    >
                      {{ fastRankingParametros.ponto_completar_prova_acima_media }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle font-weight-bold">
                      Participação em live
                    </td>

                    <td
                      class="text-center align-middle"
                    >
                      {{ fastRankingParametros.ponto_participar_live }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                  <h6>Lista de níveis</h6>
                </div>
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center">
                          <small class="font-weight-bold">Nível</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Pontos necessários</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastRanking.length">
                      <tr
                        v-for="r in fastRanking"
                        :key="r.nivel"
                      >
                        <td class="align-middle">
                          <small>
                            <img
                              :src="ajustaLinkApiAntiga(r.imagem_nivel)"
                              width="70px"
                            >
                            {{ r.nome_nivel }}
                          </small>
                        </td>
                        <td class="text-center align-middle">
                          <small>{{ r.ponto_inicial }}</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                  <h6>Lista de medalhas</h6>
                </div>
                <div class="col-12 table-responsive mt-2">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center">
                          <small class="font-weight-bold">Nível</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Porcentagem de acerto em avaliação</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastPlataformaMedalhas.length">
                      <tr
                        v-for="medalha in fastPlataformaMedalhas"
                        :key="medalha.nivel_medalha"
                      >
                        <td class="align-middle">
                          <small>
                            <img
                              :src="medalha.imagem_medalha"
                              width="70px"
                            >
                            {{ medalha.nome_medalha }}
                          </small>
                        </td>
                        <td class="text-center align-middle">
                          <small>{{ medalha.porcentagem_acerto_prova }}%</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import muralAvisos from "../components/MuralAvisos";
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
  name: "HomeInternoAluno",
  components: {
    muralAvisos,
    FullCalendar
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      fastToastNewLayout: "true",
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "100%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Ranking
      fastRanking: [],
      fastUsuarioRanking: {
        id_plataforma: 0,
        imagem_nivel: "",
        nivel: "",
        nome_nivel: "",
        ponto_final: "",
        ponto_inicial: "",
        pontos_usuario: "",
        status: "",
      },
      fastRankingParametros: {
        id_plataforma: 0,
        id_ranking_parametro: "",
        ponto_acerto_questao_quiz: 0,
        ponto_atividade_geral: 0,
        ponto_completar_curso: 0,
        ponto_completar_curso_acima_media: 0,
        ponto_completar_matriz: 0,
        ponto_completar_prova: 0,
        ponto_completar_prova_acima_media: 0,
        ponto_erro_questao_quiz: 0,
        ponto_participar_live: 0,
      },
      //Vencimentos
      fastVencimentos: {
        showAlert: true,
        vencidos: '',
        vencendo: ''
      },
      // Medalhas
      fastPlataformaMedalhas: [],
      fastUsuarioMedalhas: [],
      modalWidth: "80%",
      // Calendário
      fastCalendarOptions: {
        plugins: [
          dayGridPlugin,
        ],
        locale: 'pt-BR',
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'prevYear,nextYear'
        },
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
          year: "Ano"
        },
        initialView: 'dayGridMonth',
        initialEvents: [
        ],
      },
      fastCalendarShow: false
    };
  },

  mounted: function () {
    // Verifica Toast Layout
    this.verificaToastLayout();
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          // Se foi personalizado aplica cor nos textos
          if (this.$store.state.fastPersonalizacao.aluno_font_color) {
            Array.from(document.querySelectorAll(".aluno_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.aluno_font_color));
          }
          // Se foi personalizado aplica cor nos textos dos menus
          if (this.$store.state.fastPersonalizacao.menu_font_color) {
            Array.from(document.querySelectorAll(".menu_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.menu_font_color));
          }

          if (this.$store.state.fastPermissoes.id_pessoa) {
            this.getVencimentosAluno();
            this.getAvaliacoesCalendario();
          }

          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });

      // Sem dependências
      this.getFastPlataformaMedalhas();
      this.getFastRanking();
      this.getFastRankingParametros();
    }
  },
  methods: {
    async getFastPlataformaMedalhas() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_medalha/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataformaMedalhas = obj;
          this.getFastUsuarioMedalhas();
        } else {
          this.fastPlataformaMedalhas = [];
        }
        //console.log("fastPlataformaMedalhas", obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastUsuarioMedalhas() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_medalha/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastUsuarioMedalhas = obj;
        } else {
          this.fastUsuarioMedalhas = [];
        }
        //console.log("fastUsuarioMedalhas", obj);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastRanking() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_ranking/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastRanking = obj;
        } else {
          this.fastRanking = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastRankingParametros() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_ranking_parametros/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastRankingParametros = obj[0];
        } else {
          this.fastRankingParametros = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getVencimentosAluno(){       
      this.$store.state.fastCarregando = true;

      this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_contas_receber_aluno", `IdPlataforma=${this.$route.params.id_plataforma}&IdPessoaAluno=${this.$store.state.fastPermissoes.id_pessoa}`)
      .then(res => {
        if (res.length) {
          const boletos = res.filter(item => !item.cancelado && !item.pago).sort((a, b) => (a.data_vencimento > b.data_vencimento ? 1 : -1))
          const dataAtual = new Date();
          dataAtual.setHours(0, 0, 0, 0);

          const boletosVencidos = boletos.filter(e => new Date(e.data_vencimento).setHours(0, 0, 0, 0) < dataAtual);
          const boletosVencendo = boletos.filter(e => (new Date(e.data_vencimento).setHours(0, 0, 0, 0) - dataAtual) / (1000 * 60 * 60 * 24) <= 3 && new Date(e.data_vencimento) >= dataAtual);

          if(boletosVencidos.length) {
            this.fastVencimentos.vencidos = `${boletosVencidos.length} ${boletosVencidos.length > 1 ? 'boletos' : 'boleto'}`;
          } 
          else if (boletosVencendo.length) {
            const dataVencimento = new Date(boletosVencendo[0].data_vencimento);
            dataVencimento.setHours(0, 0, 0, 0);

            const diff = (dataVencimento - dataAtual) / (1000 * 60 * 60 * 24);
            const texto = diff === 0 ? 'hoje' : diff === 1 ? 'em 1 dia' : `em ${diff} dias`;
            const dataFormatada = this.formataDataT(boletosVencendo[0].data_vencimento);
            
            this.fastVencimentos.vencendo = `${texto} (${dataFormatada})`;
          } else {
            this.fastVencimentos.vencidos = ''
            this.fastVencimentos.vencendo = ''
          }
        } else {
          this.fastVencimentos.vencidos = ''
          this.fastVencimentos.vencendo = ''
        }

      this.$store.state.fastCarregando = false;

      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.$store.state.fastCarregando = false;
      })
    },
    async getAvaliacoesCalendario(){       
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_aluno/lista_todas_avaliacoes",`id_plataforma=${this.$route.params.id_plataforma}&id_pessoa_aluno=${this.$store.state.fastPermissoes.id_pessoa}`)
      .then(res => {
        console.log('getAvaliacoes', res)
        if (res.length) {
          this.fastCalendarOptions.initialEvents = []
          res.forEach(a => {
            let dataInicio = new Date(a.data_inicio);
            dataInicio.setDate(dataInicio.getDate() + a.liberar_prazo);

            let avObj = {
              id: a.id_secretaria_avaliacao, 
              title: `${a.titulo_avaliacao} - ${a.nome_curso} - ${a.nome_secretaria_curso}`, 
              start: `${dataInicio.toISOString().replace(/T.*$/, '')}T00:00:00`,
              groupId: `/plataforma/${a.id_plataforma}/aluno/turma/${a.id_turma}?matricula=${this.base64encode(a.id_matricula)}`
            }           
            this.fastCalendarOptions.initialEvents.push(avObj)
            this.fastCalendarShow = true;
          });
        } else {
          this.fastCalendarShow = true;
        }
      }).catch(e => {
        console.log(e)
        this.fastCalendarShow = true;
        this.exibeToasty(e, "error")
      })
    },
    verificaToastLayout() {
      const temp = localStorage.getItem('fastToastLayout');
      this.fastToastNewLayout = temp || this.fastToastNewLayout;
      localStorage.setItem('fastToastLayout', this.fastToastNewLayout);
    },
    alteraToastLayout(){
      this.fastToastNewLayout = !this.fastToastNewLayout
      localStorage.setItem('fastToastLayout', this.fastToastNewLayout)
    },
  },
};
</script>

<style>


</style>
